
























































































































































































































































.content-body, .content, .wrapper, #app {
  min-height: calc(100vh - 60px);
  position: relative;
}
.container {
  padding-bottom: 35px;
}
footer.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 50px;
}
.content-body {
  background-blend-mode: multiply;
}
